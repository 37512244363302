import * as api from '@/api/ApiClient';
import * as base from '@/api/ApiClientBase';
import { Localizer } from '@/api/Localizer';
import {IRolesDefinition} from "@/components/pages/security/securityProfile/SecurityProfileInterfaces";
import {DimensionSecurity, HierarchySecurity} from "@/api/ApiClient";

export class Profile {
    public static Localizer: Localizer;
    public Name: string;
    public OriginalName: string | null = null;

    public Definition: api.DimensionSecurity[];

    public RolesDefinition: Array<IRolesDefinition>;
    public OriginalDefinition: string | null = null;

    public Loading: boolean;

    constructor(name: string, isNew: boolean = false) {
        this.Name = name;
        this.Definition = [];
        this.RolesDefinition = [];
        this.Loading = false;
        if (!isNew)
            this.OriginalName = name;
    }

    public async LoadDefinition() {
        this.Loading = true;
        var auth_client = new base.AuthClient();
        await auth_client.ensureToken();
        var client = new api.SecurityClient(auth_client);
        this.Definition = await client.getDimensionSecurity(this.OriginalName!);
        this.setRolesDefinition();
        this.Loading = false;
        try {
            this.OriginalDefinition = JSON.stringify(this.Definition);
        } catch (e) {
            console.error(e)
        }
    }

    public async DeleteDefinition(client: api.SecurityClient | null): Promise<string | null> {
        if (client === null) {
            let auth_client = new base.AuthClient();
            client = new api.SecurityClient(auth_client);
        }

        if (!this.IsNew) {
            await client.deleteDimensionSecurityProfile(this.OriginalName!);
            return Profile.Localizer.Localize("DimensionProfilesView_Removed_profile_") + " " + this.OriginalName;
        }

        return null;
    }

    public async SaveDefinition(client: api.SecurityClient | null): Promise<string | null> {
        if (client === null) {
            let auth_client = new base.AuthClient();
            await auth_client.ensureToken();
            client = new api.SecurityClient(auth_client);
        }

        if (this.IsNew) {
            await client.createDimensionSecurityProfile(this.Name, this.Definition);
            this.OriginalDefinition = JSON.stringify(this.Definition);
            this.OriginalName = this.Name;
            return Profile.Localizer.Localize("DimensionProfilesView_Created_profile_") + " " + this.Name;
        }
        else if (this.IsLoaded) {
            if (this.NameChanged) {
                await client.createDimensionSecurityProfile(this.Name, this.Definition);
                await client.deleteDimensionSecurityProfile(this.OriginalName!);
                this.OriginalDefinition = JSON.stringify(this.Definition);
                let originalName = this.OriginalName;
                this.OriginalName = this.Name;
                return Profile.Localizer.Localize("DimensionProfilesView_Renamed_profile_") + " " + originalName + " as " + this.Name;
            }
            else if (this.DefinitionChanged) {
                await client.updateDimensionSecurity(this.Name, this.Definition);
                this.OriginalDefinition = JSON.stringify(this.Definition);
                return Profile.Localizer.Localize("DimensionProfilesView_Updated_profile_") + " " + this.Name;
            }
        }

        return null;
    }

    public get IsNew() {
        return this.OriginalName === null;
    }

    public get IsLoaded() {
        return this.IsNew || this.OriginalDefinition !== null;
    }

    public get NameChanged() {
        return this.Name !== this.OriginalName;
    }

    public get DefinitionChanged() {
        return JSON.stringify(this.Definition) !== this.OriginalDefinition;
    }

    public setRolesDefinition() {
        const roles: Array<IRolesDefinition> = [];
        this.Definition.forEach((definition:DimensionSecurity ) => {
            definition.security?.forEach((hierarchySecurity: HierarchySecurity) => {
                if (!hierarchySecurity.role) {
                    return;
                }
                const hasRole = roles.some(r => {
                   if (r.role === hierarchySecurity.role) {
                       r.dimensions.push(definition)
                       return true;
                   }
                   return false;
                });

                if (hasRole) {
                   return;
                }

                roles.push({
                    role: hierarchySecurity.role,
                    dimensions: [definition]
                });
            })
        });
        this.RolesDefinition = roles;
    }
}
